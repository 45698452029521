import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import BgImage from '../images/logo-bg.svg';

import styles from './promo.module.scss';

const AboutPage = props => (
  <Layout location={props.location} className={styles.root} disablePromoPopup navAlwaysFixed>
    <SEO title="最新優惠" />
    <div className={styles.wrapper}>
      <section className={styles.lead}>
        <h1>最新優惠</h1>
        <div className={styles.bgImage}>
          <BgImage width={null} height={null} />
        </div>
      </section>
      <section className={styles.promos}>
        <div className={styles.promo}>
          <header>客戶推薦獎賞</header>
          <div className={styles.text}>
            <p>任何現有客戶成功推薦新客戶使用本公司之服務，雙方均可獲得</p>
            <div className={styles.price}>
              <div className={styles.symbol}>$</div>
              <div className={styles.amount}>300</div>
              <div>
                <div className={styles.cash}>現金</div>
                <div className={styles.coupon}>或超市禮卷</div>
              </div>
            </div>
          </div>
          <div className={styles.disclaimer}>
            本公司有權對所上迷你倉的優惠及價格作出任何修訂，一切金額將跟據發票及迷你倉使用許可書所訂明為準。
          </div>
        </div>
        <div className={styles.promo}>
          <header>長期客户優惠</header>
          <div className={styles.text}>
            <p>租半年或以上，除享受7折優惠，更送入倉免費運輸</p>
            <div className={styles.discount}>
              <div className={styles.number}>7</div>
              <div>折</div>
            </div>
          </div>
          <div className={styles.disclaimer}>
            本公司有權對所上迷你倉的優惠及價格作出任何修訂，一切金額將跟據發票及迷你倉使用許可書所訂明為準。
          </div>
        </div>
        <div className={styles.promo}>
          <header>早更小鳥</header>
          <div className={styles.text}>
            <p>於使用前30天訂倉，額外追加5%優惠</p>
            <div className={styles.discount}>
              <div className={styles.number}>95</div>
              <div>折</div>
            </div>
          </div>
          <div className={styles.disclaimer}>
            本公司有權對所上迷你倉的優惠及價格作出任何修訂，一切金額將跟據發票及迷你倉使用許可書所訂明為準。
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default AboutPage;
